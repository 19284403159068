
        import V from 'voUtils/V.js';
        import Vue from 'vue';
        import {voMsg,voMsgPl} from './voUtils/voI18n.js';
        import DateEx from './voUtils/DateEx.js';

        Vue.prototype.VOFFICE = window.VOFFICE;

        var voFormatDate = DateEx.formatDate;

        import AirbnbStyleDatepicker from 'base/vue-airbnb-style-datepicker'
        import 'base/vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

        var vueApps = {};

        var methods = {voMsg,voMsgPl,voFormatDate};

        
            import App0 from './app/unit.js';
            vueApps['app/unit.js'] = App0;
        

            import App1 from './app/navbar.js';
            vueApps['app/navbar.js'] = App1;
        

            import App2 from './app/search.js';
            vueApps['app/search.js'] = App2;
        

            import App3 from './app/dummy.js';
            vueApps['app/dummy.js'] = App3;
        

            import App4 from './SeaviewPageUnits.js';
            vueApps['SeaviewPageUnits.js'] = App4;
        

            import App5 from './TypeHousePageUnits.js';
            vueApps['TypeHousePageUnits.js'] = App5;
        

            import App6 from './HouseArchsumPageUnits.js';
            vueApps['HouseArchsumPageUnits.js'] = App6;
        

            import App7 from './FamilyPageUnits.js';
            vueApps['FamilyPageUnits.js'] = App7;
        

            import App8 from './DogPageUnits.js';
            vueApps['DogPageUnits.js'] = App8;
        

            import App9 from './SaunaPageUnits.js';
            vueApps['SaunaPageUnits.js'] = App9;
        

            import App10 from './FiresidePageUnits.js';
            vueApps['FiresidePageUnits.js'] = App10;
        

            import App11 from './TypeFlatPageUnits.js';
            vueApps['TypeFlatPageUnits.js'] = App11;
        

            import App12 from './AngebotePageUnits.js';
            vueApps['AngebotePageUnits.js'] = App12;
        

            import App13 from './AngebotePageUnits2.js';
            vueApps['AngebotePageUnits2.js'] = App13;
        

            import App14 from './HouseMorsumPageUnits.js';
            vueApps['HouseMorsumPageUnits.js'] = App14;
        

            import App15 from './recommendedUnits.js';
            vueApps['recommendedUnits.js'] = App15;
        

            import App16 from './HomePageUnits2.js';
            vueApps['HomePageUnits2.js'] = App16;
        

            import App17 from './HomePageUnits.js';
            vueApps['HomePageUnits.js'] = App17;
        

            import App18 from './FlatMorsumPageUnits.js';
            vueApps['FlatMorsumPageUnits.js'] = App18;
        

            import App19 from './HouseKeitumPageUnits.js';
            vueApps['HouseKeitumPageUnits.js'] = App19;
        

            import App20 from './FlatArchsumPageUnits.js';
            vueApps['FlatArchsumPageUnits.js'] = App20;
        

            import App21 from './ThatchedRoofPageUnits.js';
            vueApps['ThatchedRoofPageUnits.js'] = App21;
        

            import App22 from './BeachChairPageUnits.js';
            vueApps['BeachChairPageUnits.js'] = App22;
        

        
                import Comp23 from './comp/ImgFallback.vue';
                Vue.component('img-fallback',Comp23);
            

                import Comp24 from './comp/PhotoSwipe.js';
                Vue.component('voffice-photoswipe',Comp24);
            

                import Comp25 from './comp/MaxTextSize.js';
                Vue.component('voffice-max-text-size',Comp25);
            

                import Comp26 from './comp/FavElement.vue';
                Vue.component('voffice-fav-element',Comp26);
            

                import Comp27 from './ImageGalleryPagination.js';
                Vue.component('image-gallery',Comp27);
            

                import Comp28 from './comp/DateRange.vue';
                Vue.component('voffice-daterange',Comp28);
            

                import Comp29 from './comp/gmap.js';
                Vue.component('voffice-gmap',Comp29);
            

                import Comp30 from './comp/GuestBox.vue';
                Vue.component('voffice-guestbox',Comp30);
            

                import Comp31 from './comp/PopupBox.vue';
                Vue.component('voffice-popup-box',Comp31);
            

                import Comp32 from './comp/SearchFilterTokens.vue';
                Vue.component('search-filter-tokens',Comp32);
            

                import Comp33 from './comp/ContactForm.js';
                Vue.component('voffice-form',Comp33);
            

                import Comp34 from './comp/ErrorMessage.vue';
                Vue.component('voffice-error-message',Comp34);
            

                import Comp35 from './NewsletterSubscription.js';
                Vue.component('voffice-newsletter-subscription',Comp35);
            

                import Comp36 from './comp/DocBox.vue';
                Vue.component('voffice-doc-box',Comp36);
            

                import Comp37 from './comp/Paginate.vue';
                Vue.component('voffice-paginate',Comp37);
            

                import Comp38 from './comp/CookieBox.vue';
                Vue.component('voffice-cookie-box',Comp38);
            

                import Comp39 from './comp/Voucher.vue';
                Vue.component('voffice-voucher',Comp39);
            

                import Comp40 from './comp/openstreetmap.vue';
                Vue.component('voffice-openstreetmap',Comp40);
            

                import Comp41 from './comp/Booking.js';
                Vue.component('voffice-booking',Comp41);
            

        Vue.mixin({
            methods: methods
        });

        const datepickerOptions = {
            days: voMsg('weekdayslist').split(','),
            daysShort: voMsg('weekdayslist.short').split(','),
            monthNames: voMsg('monthlist.full').split(',')
        };
        Vue.use(AirbnbStyleDatepicker, datepickerOptions);


    	var passThrough = function (val) {
			return val || '';
		};

    	var filterCodes = [
    	    'vlang',
    	    'vautolang',
    	    'vsoption',
    	    'vsPropOption'
    	  ];

        for (let fc of filterCodes) {
    		Vue.filter(fc, passThrough);
    	};


        var vueAppEls = document.body.querySelectorAll('[vo-vue-app]');

        for (let el of vueAppEls) {
            let vName = el.getAttribute("vo-vue-app");
            let v = vueApps[vName];
            if (v) {
                v.el = el;
                V.log("creating vue app",vName);

                let app = new Vue(v);
            }

        }

        VOFFICE.afterAllLoaded = function(){
            document.documentElement.classList.add("app-loaded");
        };

        window.addEventListener('beforeunload', function (event) {
        	document.documentElement.classList.add("app-leaving");
        });


    